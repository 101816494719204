import { useAuth } from "app/context/auth.context";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../ui/button.component";
import { Input } from "../ui/input.component";
import styles from "./sign-up.module.scss";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { RequestError } from "services-hooks/config";
import { Error, Session } from "services-hooks/types";
import { useTranslate } from "app/hooks/lang";
import { useFeaturesContext } from "app/context/features.context";
import { BackButtonBar } from "../back-button/back-button.component";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";

interface SignUpProps {
  onClose: VoidFunction;
  onSuccess?: (session: Session) => void;
}

interface SignupFormValue {
  email: string;
  password: string;
}

export const SignUp: React.FC<SignUpProps> = ({ onClose, onSuccess }) => {
  const t = useTranslate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignupFormValue>();
  const { signUp, signInUser, redirectToFacebookLogin } = useAuth();
  const { activeInput } = useVirtualKeyboardContext();
  const { facebookLoginEnabled } = useFeaturesContext();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (value: SignupFormValue) => {
    setIsLoading(true);
    try {
      await signUp(value.email, value.password);
      const session = await signInUser(value.email, value.password);
      if (onSuccess) {
        onSuccess(session);
      }
    } catch (e) {
      if (e instanceof RequestError) {
        if (e.response?.data.errors && e.response?.data.errors.length) {
          e.response?.data.errors.forEach((error: Error) => {
            if (error.field) {
              setError(error.field as "email" | "password", {
                type: "manual",
                message: error.field_message,
              });
            } else if (error.code === "user_exists") {
              setError("email", {
                type: "manual",
                message: t("auth.email-exists-error"),
              });
            } else {
              setError("email", {
                type: "manual",
                message: error.message,
              });
            }
          });
        }
      }
    }

    setIsLoading(false);
  };
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);

  useGoBackEvent("SignUp", goBackCallback, !activeInput);

  return (
    <>
      <BackButtonBar onClick={onClose} />
      <div className={styles.container}>
        <h2>{t("sign-up")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_group}>
            <div data-navigate-row>
              <Input
                {...register("email", { required: true })}
                placeholder={t("email")}
                error={
                  errors.email?.type === "required"
                    ? t("email-is-required")
                    : errors.email?.message
                }
                data-navigate-item
                disabled={isLoading}
                onFocus={(e) => {
                  setTimeout(() => {
                    e.target.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 100);
                }}
              />
            </div>
            <div data-navigate-row>
              <Input
                {...register("password", {
                  required: true,
                  minLength: 8,
                  maxLength: 30,
                })}
                type="password"
                placeholder={t("password")}
                error={
                  errors.password?.type === "required"
                    ? t("password-is-required")
                    : errors.password?.type === "minLength" ||
                      errors.password?.type === "maxLength"
                    ? t("auth.password-length-validation")
                    : errors.password?.message
                }
                data-navigate-item
                disabled={isLoading}
                onFocus={(e) => {
                  setTimeout(() => {
                    e.target.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 100);
                }}
              />
            </div>
          </div>
          <div data-navigate-row>
            <Button
              disabled={isLoading}
              loading={isLoading}
              data-navigate-item
              type="submit"
            >
              {t("create-an-account")}
            </Button>
          </div>

          <div className={styles.accept_terms}>{t("accept-terms")}</div>

          {facebookLoginEnabled && (
            <div className={styles.facebook_btn_wrapper} data-navigate-row>
              <Button
                className={styles.facebook_btn}
                data-navigate-item
                type="button"
                onClick={() => redirectToFacebookLogin()}
              >
                <img src="/facebook-white.png" alt="facebook" />
                {t("log-in-with-facebook")}
              </Button>
            </div>
          )}
        </form>
      </div>
    </>
  );
};
