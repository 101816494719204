import * as React from "react"
import { SVGProps, memo } from "react"

const Bookmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em" height="1em" viewBox="0 0 69 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M48.875 9.118h-28.75c-3.163 0-5.721 2.588-5.721 5.75l-.029 46L34.5 52.243l20.125 8.625v-46c0-3.162-2.587-5.75-5.75-5.75Z"
        fill="#000"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h69v69H0z" />
      </clipPath>
    </defs>
  </svg>
)

const MemoBookmark = memo(Bookmark)
export default MemoBookmark
