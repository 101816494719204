import React from "react";
import { SVGProps, memo } from "react"

const HeartOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 81 76" fill="none" {...props}>
    <g filter="url(#prefix__filter0_d_4529_10429)">
      <mask id="prefix__a" fill="#fff">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.151.25c-4.737 0-7.791.578-11.292 2.335a20.126 20.126 0 00-3.642 2.346 20.55 20.55 0 00-3.496-2.239C33.153.883 29.941.25 25.374.25 13.14.25 4.25 10.638 4.25 24.129c0 10.189 5.543 20.03 15.869 29.547 5.42 4.996 12.34 9.94 17.295 12.57l2.836 1.504 2.836-1.505c4.955-2.63 11.875-7.573 17.295-12.569C70.707 44.16 76.25 34.319 76.25 24.13 76.25 10.781 67.276.302 55.151.25z"
        />
      </mask>
      <path
        clipRule="evenodd"
        d="M55.151.25c-4.737 0-7.791.578-11.292 2.335a20.126 20.126 0 00-3.642 2.346 20.55 20.55 0 00-3.496-2.239C33.153.883 29.941.25 25.374.25 13.14.25 4.25 10.638 4.25 24.129c0 10.189 5.543 20.03 15.869 29.547 5.42 4.996 12.34 9.94 17.295 12.57l2.836 1.504 2.836-1.505c4.955-2.63 11.875-7.573 17.295-12.569C70.707 44.16 76.25 34.319 76.25 24.13 76.25 10.781 67.276.302 55.151.25z"
        stroke="#fff"
        strokeWidth={14}
        strokeLinecap="round"
        shapeRendering="geometricPrecision"
        mask="url(#prefix__a)"
      />
    </g>
    <defs>
      <filter
        id="prefix__filter0_d_4529_10429"
        x={0.25}
        y={0.25}
        width={80}
        height={75.5}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4529_10429"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_4529_10429"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)

const MemoHeartOutline = React.memo(HeartOutline);
export default MemoHeartOutline;
