
import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}

const PlaylistAlt: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 36 40" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.434 20.419a8.984 8.984 0 100-17.969 8.984 8.984 0 000 17.969zm1.853 14.813V22.72c5.434-.885 9.582-5.6 9.582-11.285 0-.453-.026-.9-.077-1.338l9.552-2.388v16.876a6.734 6.734 0 00-3.404-.92c-3.179 0-6.126 2.249-6.126 5.445 0 3.197 2.947 5.445 6.126 5.445 2.7 0 5.233-1.622 5.935-4.07.122-.204.192-.443.192-.698V5.965a1.361 1.361 0 00-1.692-1.32L22.155 7.45C20.539 3.1 16.349 0 11.436 0 5.119 0 0 5.12 0 11.434c0 6.023 4.656 10.958 10.564 11.403v7.193a6.732 6.732 0 00-3.404-.92c-3.179 0-6.126 2.248-6.126 5.445S3.981 40 7.16 40c2.7 0 5.232-1.622 5.934-4.07.122-.203.193-.442.193-.698zm15.653-8.844c-2.084 0-3.403 1.408-3.403 2.722s1.32 2.723 3.403 2.723c2.084 0 3.403-1.409 3.403-2.723 0-1.314-1.319-2.722-3.403-2.722zM7.16 31.832c-2.084 0-3.403 1.409-3.403 2.723 0 1.314 1.319 2.723 3.403 2.723s3.403-1.409 3.403-2.723c0-1.314-1.32-2.723-3.403-2.723zm5.296-26.32v4.901h4.9v2.042h-4.9v4.9h-2.042v-4.9h-4.9v-2.042h4.9v-4.9h2.042z"
        fill="#4A88FF"
      />
    </svg>
  );
}

const MemoPlaylistAlt = React.memo(PlaylistAlt);
export default MemoPlaylistAlt;
