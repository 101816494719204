import React, { useCallback, useEffect, useMemo } from "react";
import styles from "./sign-in-mobile.module.scss";
import { useGetPillowSessionV2Login_code } from "../../../services-hooks/hooks";
import { useInterval } from "react-use";
import { userActions } from "../../store/slices/user";
import { useAppDispatch } from "../../store/store";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useTranslate } from "app/hooks/lang";
import { BackButtonBar } from "../back-button/back-button.component";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";
import { Session } from "services-hooks/types";

interface SignInProps {
  onClose: VoidFunction;
  onSuccess?: (session: Session) => void;
  parentPopupId?: string | null;
}

export const SignInMobile: React.FC<SignInProps> = ({
  onClose,
  onSuccess = () => {},
}) => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const { activeInput } = useVirtualKeyboardContext();
  const { data, refetch, remove } = useGetPillowSessionV2Login_code();
  const interval = data?.data?.data?.interval || 10;
  const code = data?.data?.data?.key || "";

  useInterval(() => {
    if (code) {
      refetch();
    }
  }, interval * 1000);

  useEffect(() => {
    if (data?.data?.data?.session?.user) {
      dispatch(userActions.setSession(data?.data?.data?.session));
      onSuccess(data?.data?.data?.session);
    }
    return () => {
      remove();
    };
  }, [data?.data?.data?.session?.user?.id]);
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  useGoBackEvent("SignInMobile", goBackCallback, !activeInput);

  return (
    <>
      <BackButtonBar onClick={onClose} />
      <div className={styles.container}>
        <h2>{t("auth.login-with-mobile")}</h2>

        <div className={styles.code_wrapper}>
          <div className={styles.code}>{code}</div>
        </div>

        <div className={styles.instruction}>
          <h3>{t("auth.instructions")}:</h3>

          <div className={styles.steps}>
            <div className={styles.step}>
              <div className={styles.number}>1</div>
              <p>{t("auth.step-1")}</p>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>2</div>
              <p>{t("auth.step-2")}</p>
            </div>
            <div className={styles.step}>
              <div className={styles.number}>3</div>
              <p>{t("auth.step-3")}</p>
            </div>
          </div>
          {/* empty element to scroll to */}
          <div data-navigate-row>
            <div data-navigate-item></div>
          </div>
        </div>
      </div>
    </>
  );
};
