import React, { useCallback, useEffect, useState } from "react";
import { Button, ButtonOutline } from "../ui/button.component";
import { Divider } from "../ui/divider.component";
import styles from "./auth.module.scss";
import { ResetPassword } from "./reset-password.component";
import { SignIn } from "./sign-in.component";
import { SignUp } from "./sing-up.component";
import { SignInMobile } from "./sign-in-mobile.component";
import { useTranslate } from "app/hooks/lang";
import { useArrowKeyNavigationOverlay } from "app/hooks/keyboard-navigation.hook";
import useModalScroll from "app/hooks/modal-scroll.hook";
import { Portal } from "../ui/portal";
import MemoHeartOutline from "../icons/HeartOutline";
import MemoBookmark from "../icons/Bookmark";
import MemoOfflineOutline from "../icons/OfflineOutline";
import clsx from "clsx";
import { useFeaturesContext } from "app/context/features.context";
import MemoPlaylistAlt from "../icons/PlaylistAlt";
import { BackButtonBar } from "../back-button/back-button.component";
import { Session } from "services-hooks/types";

const enum ActiveScreen {
  SIGN_UP = "SIGN_UP",
  SIGN_IN = "SIGN_IN",
  SIGN_IN_MOBILE = "SIGN_IN_MOBILE",
  PASSWORD_RESET = "PASSWORD_RESET",
}

interface AuthProps {
  onClose?: VoidFunction;
  onSuccess?: (session: Session) => void;
  parentPopupId?: string | null;
}

export const Auth: React.FC<AuthProps> = ({
  onClose,
  onSuccess,
  parentPopupId = null,
}) => {
  const t = useTranslate();
  const [activeScreen, setActiveScreen] = useState<ActiveScreen | null>(null);
  const { downloadEnabled, premiumVersion, facebookLoginEnabled } =
    useFeaturesContext();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [activeScreen]);
  const onCloseCallback = useCallback(
    () => setActiveScreen(null),
    [setActiveScreen]
  );
  const renderScreen = () => {
    switch (activeScreen) {
      case ActiveScreen.SIGN_IN:
        return (
          <SignIn
            onClose={onCloseCallback}
            onSuccess={onSuccess}
            onForgotPassword={() =>
              setActiveScreen(ActiveScreen.PASSWORD_RESET)
            }
          />
        );
      case ActiveScreen.SIGN_IN_MOBILE:
        return (
          <SignInMobile
            parentPopupId={parentPopupId}
            onClose={onCloseCallback}
            onSuccess={onSuccess}
          />
        );
      case ActiveScreen.SIGN_UP:
        return <SignUp onClose={onCloseCallback} onSuccess={onSuccess} />;
      case ActiveScreen.PASSWORD_RESET:
        return (
          <ResetPassword
            onClose={onCloseCallback}
            onGoToLogin={() => setActiveScreen(ActiveScreen.SIGN_IN)}
          />
        );

      default:
        return (
          <>
            {onClose && <BackButtonBar onClick={onClose} />}

            <div className={styles.wrapper}>
              <div className={styles.inner}>
                <div className={styles.auth_points}>
                  {premiumVersion && (
                    <div className={styles.auth_point}>
                      <div className={clsx(styles.auth_icon_wrapper)}>
                        <MemoPlaylistAlt />
                      </div>
                      <div className={styles.auth_texts}>
                        <h1>{t("auth.export-to-playlist")}</h1>
                        <p>{t("auth.export-to-playlist-desc")}</p>
                      </div>
                    </div>
                  )}
                  <div className={styles.auth_point}>
                    <div
                      className={clsx(styles.auth_icon_wrapper, styles.heart)}
                    >
                      <MemoHeartOutline />
                    </div>
                    <div className={styles.auth_texts}>
                      <h1>{t("auth.save-your-favorites")}</h1>
                      <p>{t("auth.synchronize-your-account")}</p>
                    </div>
                  </div>
                  <div className={styles.auth_point}>
                    <div
                      className={clsx(
                        styles.auth_icon_wrapper,
                        styles.bookmark
                      )}
                    >
                      <MemoBookmark />
                    </div>
                    <div className={styles.auth_texts}>
                      <h1>{t("auth.resume-playback")}</h1>
                      <p>{t("auth.resume-podcasts")}</p>
                    </div>
                  </div>
                  {downloadEnabled && (
                    <div className={styles.auth_point}>
                      <div
                        className={clsx(
                          styles.auth_icon_wrapper,
                          styles.offline
                        )}
                      >
                        <MemoOfflineOutline />
                      </div>
                      <div className={styles.auth_texts}>
                        <h1>{t("auth.offline-podcasts")}</h1>
                        <p>{t("auth.download-podcasts")}</p>
                      </div>
                    </div>
                  )}
                </div>

                <div data-navigate-row>
                  <Button
                    className={styles.btn}
                    data-navigate-item
                    onClick={() => setActiveScreen(ActiveScreen.SIGN_UP)}
                  >
                    {t("sign-up")}
                  </Button>
                </div>
                <Divider>
                  <span className={styles.divider_content}>{t("auth.or")}</span>
                </Divider>
                <div data-navigate-row>
                  <ButtonOutline
                    className={styles.btn}
                    data-navigate-item
                    onClick={() => setActiveScreen(ActiveScreen.SIGN_IN)}
                  >
                    {t("log-in")}
                  </ButtonOutline>
                </div>
                <div data-navigate-row>
                  <ButtonOutline
                    className={styles.btn}
                    data-navigate-item
                    onClick={() => setActiveScreen(ActiveScreen.SIGN_IN_MOBILE)}
                  >
                    {t("log-in-with-my-mobile")}
                  </ButtonOutline>
                </div>
              </div>
            </div>
          </>
        );
    }
  };
  return renderScreen();
};

interface AuthGuardPopupProps {
  onClose: VoidFunction;
  onSuccess?: (session: Session) => void;
  moveUnderHeader?: boolean;
}

export const AuthGuardPopup: React.FC<AuthGuardPopupProps> = ({
  onClose,
  onSuccess = () => {},
  moveUnderHeader,
}) => {
  const wrapperRef = useArrowKeyNavigationOverlay(!moveUnderHeader);
  useModalScroll();
  return (
    <Portal>
      <div
        className={clsx(styles.popup_wrapper, {
          [styles.under_header]: moveUnderHeader,
        })}
        ref={wrapperRef}
      >
        <Auth onClose={onClose} onSuccess={onSuccess} />
      </div>
    </Portal>
  );
};
