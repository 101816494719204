import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const ArrowBack: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 60 60" fill="none" {...props}>
      <path
        d="M50 27.5H19.575L33.55 13.525 30 10 10 30l20 20 3.525-3.525L19.575 32.5H50v-5z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoArrowBack = React.memo(ArrowBack);
export default MemoArrowBack;
