import { useTranslate } from "app/hooks/lang";
import clsx from "clsx";
import MemoArrowBack from "../icons/ArrowBack";
import styles from "./back-button.module.scss";

interface BackButtonBarProps {
  onClick: VoidFunction;
  className?: string;
  buttonClassName?: string;
}

interface BackButtonProps {
  onClick: VoidFunction;
  className?: string;
}

export const BackButtonBar: React.FC<BackButtonBarProps> = ({
  onClick,
  className,
  buttonClassName,
}) => {
  return (
    <div data-navigate-row className={clsx(styles.wrapper, className)}>
      <BackButton onClick={onClick} className={buttonClassName} />
    </div>
  );
};

export const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  className,
}) => {
  const t = useTranslate();
  return (
    <button
      data-navigate-item
      className={clsx(styles.back, className)}
      onClick={onClick}
    >
      <MemoArrowBack />
      <span>{t("back")}</span>
    </button>
  );
};
