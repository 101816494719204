import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "../ui/button.component";
import { Input } from "../ui/input.component";
import styles from "./reset-password.module.scss";
import { useAuth } from "../../context/auth.context";
import MemoLoader from "../icons/Loader";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useTranslate } from "app/hooks/lang";
import { BackButtonBar } from "../back-button/back-button.component";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";

interface ResetPasswordProps {
  onClose: VoidFunction;
  onGoToLogin: VoidFunction;
}

interface ResetPasswordFormValue {
  email: string;
}

export const ResetPassword: React.FC<ResetPasswordProps> = ({
  onClose,
  onGoToLogin,
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<ResetPasswordFormValue>();
  const { resetPassword } = useAuth();
  const [targetEmail, setTargetEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslate();
  const { activeInput } = useVirtualKeyboardContext();
  const onSubmit = async (value: ResetPasswordFormValue) => {
    try {
      setIsLoading(true);
      await resetPassword(value.email);
      onSuccess(value.email);
    } catch (e) {
      setError("email", {
        type: "manual",
        message: t("auth.reset-password-error"),
      });
    }
    setIsLoading(false);
  };

  const onSuccess = (email: string) => {
    setTargetEmail(email);
  };
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  useGoBackEvent("ResetPassword", goBackCallback, !activeInput);

  return (
    <>
      <BackButtonBar onClick={onClose} />
      <div className={styles.container}>
        <h2>{t("auth.reset-password")}</h2>
        {!isLoading || errors?.email ? (
          !targetEmail && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={styles.form_group}>
                <div data-navigate-row>
                  <Input
                    {...register("email", { required: t("field-required") })}
                    placeholder={"Email"}
                    error={errors?.email?.message}
                    data-navigate-item
                    onFocus={(e) => {
                      setTimeout(() => {
                        e.target.scrollIntoView({
                          behavior: "smooth",
                          block: "center",
                        });
                      }, 100);
                    }}
                  />
                </div>
              </div>
              <div data-navigate-row>
                <Button data-navigate-item>{t("send")}</Button>
              </div>
            </form>
          )
        ) : (
          <div className={styles.loader_wrapper}>
            <MemoLoader />
          </div>
        )}
        {targetEmail && (
          <div className={styles.success_info}>
            <p>{t("auth.reset-password-success", { email: targetEmail })}</p>
            <div data-navigate-row>
              <Button data-navigate-item onClick={onGoToLogin}>
                {t("log-in")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
