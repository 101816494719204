import { useAuth } from "app/context/auth.context";
import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, ButtonText } from "../ui/button.component";
import { Input } from "../ui/input.component";
import styles from "./sign-in.module.scss";
import { useTranslate } from "app/hooks/lang";
import { useGoBackEvent } from "app/hooks/go-back.hook";
import { useFeaturesContext } from "app/context/features.context";
import { BackButtonBar } from "../back-button/back-button.component";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";
import { Session } from "services-hooks/types";

interface SignInProps {
  onClose: VoidFunction;
  onSuccess?: (session: Session) => void;
  onForgotPassword?: VoidFunction;
}

interface SigninFormValue {
  email: string;
  password: string;
}

export const SignIn: React.FC<SignInProps> = ({
  onClose,
  onSuccess = () => {},
  onForgotPassword = () => {},
}) => {
  const t = useTranslate();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SigninFormValue>();
  const { facebookLoginEnabled } = useFeaturesContext();
  const { activeInput } = useVirtualKeyboardContext();
  const { signInUser, redirectToFacebookLogin } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (value: SigninFormValue) => {
    try {
      setIsLoading(true);
      const session = await signInUser(value.email, value.password);
      onSuccess(session);
    } catch (e) {
      setError("email", {
        type: "manual",
        message: t("could-not-sign-in-please-check-your-email-and-password"),
      });
    }
    setIsLoading(false);
  };
  const goBackCallback = useCallback(() => {
    onClose();
  }, [onClose]);
  useGoBackEvent("SignIn", goBackCallback, !activeInput);

  return (
    <>
      <div className={styles.back_container}>
        <BackButtonBar onClick={onClose} />
      </div>

      <div className={styles.container}>
        <h2>{t("log-in")}</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.form_group}>
            <div data-navigate-row>
              <Input
                {...register("email", {
                  required: true,
                })}
                data-navigate-item
                placeholder={t("email")}
                disabled={isLoading}
                error={
                  errors?.email?.type === "required"
                    ? t("email-is-required")
                    : errors?.email?.message
                }
                onFocus={(e) => {
                  setTimeout(() => {
                    e.target.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 100);
                }}
              />
            </div>
            <div data-navigate-row>
              <Input
                {...register("password", {
                  required: true,
                })}
                type="password"
                data-navigate-item
                placeholder={t("password")}
                disabled={isLoading}
                error={
                  errors?.password?.type === "required"
                    ? t("password-is-required")
                    : errors?.password?.message
                }
                onFocus={(e) => {
                  setTimeout(() => {
                    e.target.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }, 100);
                }}
              />
            </div>
          </div>
          <div data-navigate-row>
            <Button
              loading={isLoading}
              data-navigate-item
              type="submit"
              disabled={isLoading}
            >
              {t("log-in")}
            </Button>
          </div>
        </form>
        <div data-navigate-row>
          <ButtonText
            data-navigate-item
            className={styles.forgot_button}
            onClick={onForgotPassword}
          >
            {t("forgot-password")}
          </ButtonText>
        </div>
        {facebookLoginEnabled && (
          <div className={styles.facebook_btn_wrapper} data-navigate-row>
            <Button
              className={styles.facebook_btn}
              data-navigate-item
              type="button"
              onClick={() => redirectToFacebookLogin()}
            >
              <img src="/facebook-white.png" alt="facebook" />
              {t("log-in-with-facebook")}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
