import { useFeaturesContext } from "app/context/features.context";
import { useVirtualKeyboardContext } from "app/context/virtual-keyboard.context";
import clsx from "clsx";
import React, { ReactElement, useImperativeHandle, useRef } from "react";
import styles from "./input.module.scss";

interface InputProps {
  error?: string | boolean | null;
  message?: string | number | null;
  icon?: ReactElement;
  dark?: boolean;
}

export const Input = React.forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement> & InputProps
>(({ className, icon, dark, ...inputProps }, inRef) => {
  const ref = useRef<HTMLInputElement>(null);
  useImperativeHandle(inRef, () => ref.current!, [ref]);

  const { activeInput } = useVirtualKeyboardContext();
  return (
    <>
      <div className={clsx(styles.input, { [styles.dark]: dark }, className)}>
        <label
          className={clsx(styles.input__label, {
            [styles.error]: !!inputProps.error,
          })}
        >
          {inputProps.label}
        </label>
        <input
          type="text"
          className={clsx(styles.input__control, {
            [styles.error]: !!inputProps.error,
            [styles.active]: activeInput && activeInput === ref?.current,
          })}
          ref={ref}
          {...inputProps}
        />
        {icon ? React.cloneElement(icon, { className: styles.icon }) : null}
        {inputProps.error && typeof inputProps.error !== "boolean" && (
          <span
            data-testid={`${inputProps.name}-error-message`}
            className={clsx(styles.message, styles.message_error)}
          >
            {inputProps.error}
          </span>
        )}
        {inputProps.message !== null && (
          <span className={styles.message}>{inputProps.message}</span>
        )}
      </div>
    </>
  );
});

Input.displayName = "Input";
